export default [
  {
    name: "participants.identity.introduction",
    path: "participants/identity/introduction",
    component: () =>
      import(
        /* webpackChunkName: "identity" */ "../../views/identity/IdentityIntroduction.vue"
      ),
    meta: {
      title: "Introduction",
    },
  },
  {
    name: "participants.identity.about",
    path: "participants/identity/about",
    component: () =>
      import(
        /* webpackChunkName: "identity" */ "../../views/identity/IdentityPersonalDetails.vue"
      ),
    meta: {
      title: "About you",
    },
  },
  {
    name: "participants.identity.verify",
    path: "participants/identity/verify",
    component: () =>
      import(
        /* webpackChunkName: "identity" */ "../../views/identity/IdentityVerification.vue"
      ),
    meta: {
      title: "Verify",
    },
  },
  {
    name: "participants.identity.error",
    path: "participants/identity/verify/error",
    component: () =>
      import(
        /* webpackChunkName: "identity" */ "../../views/identity/IdentityVerificationError.vue"
      ),
    meta: {
      title: "Verification error",
    },
  },
  {
    name: "participants.identity.complete",
    path: "participants/identity/complete",
    component: () =>
      import(
        /* webpackChunkName: "identity" */ "../../views/identity/IdentityComplete.vue"
      ),
    meta: {
      title: "Complete",
    },
  },
];
