<script setup>
import { computed, useSlots } from "vue";
import Pending from "../PendingIcon";

const slots = useSlots();
const emit = defineEmits(["click"]);

const props = defineProps({
  type: {
    type: String,
    default: "button",
    validator: (value) => ["button", "submit"].includes(value),
  },
  isPending: {
    type: Boolean,
    default: false,
  },
  pendingLabel: {
    type: String,
    default: "",
  },
  isSecondary: {
    type: Boolean,
    default: false,
  },
  isTertiary: {
    type: Boolean,
    default: false,
  },
  /**
   * You can use a standard disabled HTML attribute,
   * but this is needed to manage the pending state.
   * So set the disabled button with this prop instead.
   */
  isDisabled: {
    type: Boolean,
    default: false,
  },
  icon: {
    type: Object,
    default: () => {
      return null;
    },
  },
  iconProps: {
    type: Object,
    default: () => ({}),
  },
  iconPlacement: {
    type: String,
    default: "start",
    validator: (value) => ["start", "end"].includes(value),
  },
});

const onClick = (event) => {
  emit("click", event);
};
const hasIconSuffix = computed(
  () => !!slots["icon-suffix"] || (props.icon && props.iconPlacement === "end")
);
const hasIconPrefix = computed(
  () =>
    !!slots["icon-prefix"] || (props.icon && props.iconPlacement === "start")
);

const disabled = computed(() => props.isDisabled || props.isPending);
</script>

<template>
  <button
    class="button"
    :class="{
      'button--pending': isPending,
      'button--secondary': isSecondary,
      'button--tertiary': isTertiary,
    }"
    :disabled="disabled"
    :type="type"
    @click="onClick"
  >
    <span class="button__main">
      <span class="button__pending" v-if="isPending">
        <span
          class="button__pending__content"
          v-if="pendingLabel"
          v-html="pendingLabel" />
        <pending
          class="button__pending__icon"
          is-weakest
          aria-busy="true"
          aria-live="polite"
          aria-label="Pending"
      /></span>
      <span class="button__content"
        ><span class="button__icon-prefix" v-if="hasIconPrefix"
          ><slot name="icon-prefix" /><slot name="icon"
            ><component
              :is="icon"
              v-bind="iconProps"
              v-if="icon && iconPlacement === 'start'" /></slot></span
        ><span class="button__label"><slot>Submit</slot></span
        ><span class="button__icon-suffix" v-if="hasIconSuffix"
          ><slot name="icon-suffix"
            ><component
              :is="icon"
              v-bind="iconProps"
              v-if="icon && iconPlacement === 'end'" /></slot></span
      ></span>
    </span>
  </button>
</template>
