export default Object.freeze({
  //Customer charges
  CUSTOMER_CHARGES_GENERAL:
    "https://support.moverly.com/hc/en-gb/articles/4606990530463",
  CUSTOMER_CHARGES_SELLER:
    "https://support.moverly.com/hc/en-gb/articles/4606990530463",
  CUSTOMER_CHARGES_LANDLORD:
    "https://support.moverly.com/hc/en-gb/articles/4606990530463",
  CUSTOMER_CHARGES_BUYER:
    "https://support.moverly.com/hc/en-gb/articles/4606990530463",
  CUSTOMER_CHARGES_TENANT:
    "https://support.moverly.com/hc/en-gb/articles/4606990530463",

  // Landing pages
  AGENTS:
    "https://support.moverly.com/hc/en-gb/categories/4505347403039-Agents",
  SELLERS:
    "https://support.moverly.com/hc/en-gb/categories/4505311585311-Sellers",
  CONVEYANCERS:
    "https://support.moverly.com/hc/en-gb/categories/4505311620895-Conveyancers",
  BUYERS:
    "https://support.moverly.com/hc/en-gb/categories/4505347458591-Buyers",
  LANDLORDS:
    "https://support.moverly.com/hc/en-gb/categories/4505378758175-Landlords-and-lettings",
  TENANTS:
    "https://support.moverly.com/hc/en-gb/categories/4505387367967-Renters-and-tenants",
});
