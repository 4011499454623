import Honeybadger from "@honeybadger-io/js";
import axios from "axios";
import useAuth from "@/composables/auth";
import { MOVEREADY_PDTF_API_URL } from "@/config";
import { getAppCheckToken } from "@/firebase";
import { ref } from "vue";

const { getAccessToken } = useAuth();

const identityProvider = Object.freeze({
  ONFIDO: "onfido",
  SIMPLY: "simply",
  VALIDATE: "validate",
  VALIDATE_NATIVE: "validate-native",
  YOTI: "yoti",
});

const identityProviderName = Object.freeze({
  [identityProvider.ONFIDO]: "Onfido",
  [identityProvider.YOTI]: "Yoti",
  [identityProvider.SIMPLY]: "Simply",
  [identityProvider.VALIDATE]: "Validate by LMS",
  [identityProvider.VALIDATE_NATIVE]: "Validate by Moverly",
});

const DEFAULT_IDENTITY_PROVIDER = identityProvider.VALIDATE_NATIVE;
const isFetchingIdentityProvider = ref(false);

const fetchIdentityProvider = async (transactionId) => {
  const userAccessToken = await getAccessToken();
  const appCheckToken = await getAppCheckToken();

  try {
    const identityProviderResponse = await axios.get(
      `${MOVEREADY_PDTF_API_URL}/transactions/${transactionId}/idv-provider`,
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
          ContentType: "application/json",
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );
    const identityProvider = identityProviderResponse.data;

    return identityProvider || DEFAULT_IDENTITY_PROVIDER;
  } catch (ex) {
    Honeybadger.notify(ex, {
      name: "indentification.js",
      message: "Failed to fetch the identity provider.",
      params: {
        transactionId,
      },
    });
    // Maybe throw an exception instead of using a default?
    return DEFAULT_IDENTITY_PROVIDER;
  } finally {
    isFetchingIdentityProvider.value = false;
  }
};

export default function useIdentification() {
  return {
    fetchIdentityProvider,
    identityProvider,
    identityProviderName,
    isFetchingIdentityProvider,
  };
}
