/**
 * The Checks Engine, mapping state, claims and collector statuses into Flags.
 */
import Honeybadger from "@honeybadger-io/js";
import axios from "axios";
import { MOVEREADY_PDTF_API_URL } from "@/config";
import { collection, query, where, onSnapshot } from "firebase/firestore";
import { db } from "@/firebase";
import { getAppCheckToken } from "@/firebase";
import { ref } from "vue";

const checks = ref([]);
const COLLECTOR_NAME_DELIMITER = "-";

const fetchFlagByName = (flagNameToFind, checksToFilter = checks.value) => {
  return filterFlagsByName(flagNameToFind, checksToFilter, true);
};

const findFlagsByCheckNameStartsWith = (
  checkNameStartsWith = [],
  checksToFilter = checks.value,
  returnFirstArrayItem = false
) => {
  if (typeof checkNameStartsWith === "string") {
    checkNameStartsWith = [checkNameStartsWith];
  }

  const filteredFlags = (checksToFilter || []).filter((flag) =>
    checkNameStartsWith.some((prefix) => flag.checkName?.startsWith(prefix))
  );

  if (returnFirstArrayItem) {
    return filteredFlags.length > 0 ? filteredFlags[0] : null;
  }

  return filteredFlags;
};

const filterFlagsByName = (
  namesToInclude = [],
  checksToFilter = checks.value,
  returnFirstArrayItem = false
) => {
  if (typeof namesToInclude === "string") {
    namesToInclude = [namesToInclude];
  }

  const filteredFlags = (checksToFilter || []).filter((flag) =>
    namesToInclude.includes(flag.checkName)
  );

  if (returnFirstArrayItem) {
    return filteredFlags.length > 0 ? filteredFlags[0] : null;
  }

  return filteredFlags;
};

const findFlagsByGroupName = (namesToFilterBy = [], checks = checks.value) => {
  if (typeof namesToFilterBy === "string") {
    namesToFilterBy = [namesToFilterBy];
  }

  return checks.filter(({ checkName }) => {
    const [commonGroupName] = checkName.split(COLLECTOR_NAME_DELIMITER);

    return namesToFilterBy.includes(commonGroupName);
  });
};

let unsubscribeFromSnapshotChecks;
const isLoadingChecks = ref(true);
const checksError = ref(null);
const clearChecks = () => {
  checks.value = [];
};

const subscribeToChecks = async (transactionId) => {
  isLoadingChecks.value = true;

  if (typeof unsubscribeFromSnapshotChecks === "function") {
    unsubscribeFromSnapshotChecks();
    unsubscribeFromSnapshotChecks = null;
    clearChecks();
  }

  const subscribePromise = new Promise((resolve, reject) => {
    unsubscribeFromSnapshotChecks = onSnapshot(
      query(
        collection(db, "flags"),
        where("transactionId", "==", transactionId)
        // should we specify the query to listen to more specific params
        // see two snapshots, whats the differents
      ),
      (snapshot) => {
        checks.value = snapshot.docs.map((snap) => snap.data()) || [];
        isLoadingChecks.value = false;
        resolve(checks);
      },
      (error) => {
        checks.value = [];
        checksError.value = error;
        isLoadingChecks.value = false;
        reject(error);
      }
    );
  });

  return subscribePromise;
};

const fetchChecks = subscribeToChecks;

const getCollectorByName = (nameToMatch) => {
  return checks.value.find(
    (check) => check.checkName === nameToMatch && check.source === "collector"
  );
};

const checksByPath = (pathToFilterBy) => {
  const filteredChecks = checks.value.filter((check) =>
    check.path.startsWith(pathToFilterBy)
  );

  return filteredChecks;
};

const pathToUrlParams = (path) => {
  return path.split("/").slice(1);
};

const dedupeChecks = (dirtyChecks) => {
  const unqiueChecksNames = [];
  const cleanChecks = dirtyChecks.filter((check) => {
    if (unqiueChecksNames.indexOf(check?.checkName) === -1) {
      unqiueChecksNames.push(check?.checkName);
      return true;
    }
  });

  return cleanChecks;
};

const groupChecksByCategory = (checks, categoriesToGroupBy) => {
  return categoriesToGroupBy.reduce(
    (acc, category) => ({
      ...acc,
      [category]: checks.filter((check) => check.category === category),
    }),
    {}
  );
};

const findChecksBySource = (sourcesToFilterBy, checks) => {
  if (typeof sourcesToFilterBy === "string") {
    sourcesToFilterBy = [sourcesToFilterBy];
  }

  return checks.filter(({ source }) => sourcesToFilterBy.includes(source));
};

const findChecksByCategory = (
  categoriesToFilterBy = [],
  flags = checks.value
) => {
  if (typeof categoriesToFilterBy === "string") {
    categoriesToFilterBy = [categoriesToFilterBy];
  }

  return flags.filter(({ category }) =>
    categoriesToFilterBy.includes(category)
  );
};

const audienceContentPrefixMap = Object.freeze({
  Seller: "seller",
  "Estate Agent": "estateAgent",
  Buyer: "buyer",
});

const getHeading = (flag, role = "") => {
  if (!flag?.content) return "";

  const headingPrefix = audienceContentPrefixMap[role];

  return flag.content[`${headingPrefix}Heading`] || flag.content?.heading || "";
};

const getMessage = (flag, role = "") => {
  if (!flag?.content) return "";

  const messagePrefix = audienceContentPrefixMap[role];

  return flag.content[`${messagePrefix}Message`] || flag.content?.message || "";
};

const fetchPublicChecks = async (transactionId) => {
  isLoadingChecks.value = true;
  const appCheckToken = await getAppCheckToken();

  try {
    const publicChecksResponse = await axios.get(
      `${MOVEREADY_PDTF_API_URL}/public/transactions/${transactionId}/flags`,
      {
        headers: {
          ContentType: "application/json",
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );
    checks.value = publicChecksResponse.data;
  } catch (ex) {
    Honeybadger.notify(ex, {
      message: "Failed to fetch public flags.",
      name: "checks.js",
      params: {
        transactionId,
      },
    });
  } finally {
    isLoadingChecks.value = false;
  }
};

export default function useChecks() {
  return {
    checks,
    checksByPath,
    checksError,
    dedupeChecks,
    fetchChecks,
    fetchFlagByName,
    fetchPublicChecks,
    findFlagsByCheckNameStartsWith,
    filterFlagsByName,
    findChecksByCategory,
    findChecksBySource,
    findFlagsByGroupName,
    getCollectorByName,
    getHeading,
    getMessage,
    groupChecksByCategory,
    isLoadingChecks,
    pathToUrlParams,
  };
}
