import axios from "axios";
import useAuth from "@/composables/auth";
import { MOVEREADY_ORGANISATION_API_URL } from "@/config";
import { getAppCheckToken } from "@/firebase";
import { ref } from "vue";

const { getAccessToken } = useAuth();

const isFetchingOrganisationUsers = ref(false);
const organisationUsers = ref([]);
let hasFetchedOrganisationUsers = false;

const fetchOrganisationUsers = async (organisationId) => {
  const appCheckToken = await getAppCheckToken();
  const userAccessToken = await getAccessToken();
  isFetchingOrganisationUsers.value = true;

  try {
    const organisationUsersResponse = await axios.get(
      `${MOVEREADY_ORGANISATION_API_URL}/organisations/${organisationId}/users`,
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
          ContentType: "application/json",
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );
    // Rename the property officesIds to officeIds because it's not the conventions used in the FE.
    organisationUsers.value = organisationUsersResponse.data.map((user) => ({
      ...user,
      officeIds: user.officesIds,
    }));
  } catch (ex) {
    organisationUsers.value = [];
  } finally {
    hasFetchedOrganisationUsers = true;
    isFetchingOrganisationUsers.value = false;
  }
};

export default function useOrganisationUsers() {
  return {
    fetchOrganisationUsers,
    hasFetchedOrganisationUsers,
    isFetchingOrganisationUsers,
    organisationUsers,
  };
}
