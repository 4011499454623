import crmName from "@/enums/crm-name";
import useAuth from "@/composables/auth";
import useOrganisationUsers from "@/composables/organisation/organisationUsers";
import { computed } from "vue";

const { userId } = useAuth();

const { organisationUsers } = useOrganisationUsers();

const userIntegrations = computed(() => {
  return (
    organisationUsers.value.find((user) => user.id === userId.value)
      ?.crmIntegrations || []
  );
});

const isAltoIntegrationAvailable = computed(() => {
  return userIntegrations.value.some(
    (integration) => integration.crmName === crmName.ALTO
  );
});

const isAltoIntegrationActive = computed(() => {
  const altoIntegration = userIntegrations.value.find(
    (integration) => integration.crmName === crmName.ALTO
  );

  return (
    altoIntegration?.accountId !== undefined &&
    altoIntegration?.accountId !== ""
  );
});

export default function useUserIntegrations() {
  return {
    isAltoIntegrationActive,
    isAltoIntegrationAvailable,
    userIntegrations,
  };
}
