<script setup>
import AddIcon from "@/components/library/icons/AddIcon";
import AdminIcon from "@/components/library/icons/Admin";
import Avatar from "@/components/library/avatar/Avatar";
import TaskSuccessIcon from "@/components/library/icons/TaskSuccessIcon";
import EmailIcon from "@/components/library/icons/Email";
import OfficeIcon from "@/components/library/icons/Office";
import InputButton from "@/components/library/inputs/InputButton";
import HelpIcon from "@/components/library/icons/HelpIcon";
import LogoutIcon from "@/components/library/icons/Logout";
import MenuIcon from "../components/library/icons/IconMenu.vue";
import PropertyIcon from "@/components/library/icons/Property";
import ReapitAppHeader from "@/components/affiliates/reapit/AppHeader";
import SettingsIcon from "@/components/library/icons/Settings";
import TeamIcon from "@/components/library/icons/Team";
import ToolIcon from "@/components/library/icons/Tool";
import UserIcon from "@/components/library/icons/UserIcon";
import WebhookIcon from "@/components/library/icons/Webhook";
import useUserIntegrations from "@/composables/user/userIntegrations";
import useReapit from "@/composables/reapit";
import useZendesk from "@/composables/zendesk";
import useUserProfile from "@/composables/user/userProfile";
import useBackOfficeBranding from "@/composables/brandingBackOffice";
import { computed, ref, onMounted, onUnmounted } from "vue";

const { waitAndHideZendeskWidget, waitAndShowZendeskWidget } = useZendesk();
const { isOrganisationOwner, isAdmin, fullName, userOrganisation } =
  useUserProfile();
const { isAppInDektopMode } = useReapit();
const { isAltoIntegrationAvailable } = useUserIntegrations();
const isInReapitAppDesktopMode = isAppInDektopMode();
const { brandLogo, brandName, brandLogoScale } = useBackOfficeBranding();

const isNavigationVisible = ref(false);
const navigationTrigger = ref(false);

if (isInReapitAppDesktopMode) {
  import(`@/assets/styles/themes/_reapit.scss`);
}

const allowNativePropertyAdd = computed(() => {
  return !isAltoIntegrationAvailable.value;
});

/**
 * For some reason, the router.replace() doesn't work on the dashboard page.
 * The watcher on the query params on the search composable is causing the issue.
 * So we're just redirecting to the sign-out page instead.
 */
const onLogout = async () => {
  window.location.href = "/sign-out";
};

const toggleNavigationVisibility = () => {
  if (isNavigationVisible.value) {
    closeMenu();
  } else {
    openMenu();
  }
};

const closeMenu = () => {
  document.body.classList.remove("body--site-account-navigation");
  isNavigationVisible.value = false;
  waitAndShowZendeskWidget();
};

const openMenu = () => {
  document.body.classList.add("body--site-account-navigation");
  isNavigationVisible.value = true;
  waitAndHideZendeskWidget();
};

const documentClick = (event) => {
  let element = navigationTrigger.value;
  let target = null;
  if (event && event.target) {
    target = event.target;
  }

  // This is here to only close the navigation if
  // the user clicks a link that needs to load a page.
  // Don't close the menu if the user clicks on a
  // button that shows a sub navigation.
  if (
    element &&
    element !== target &&
    !element.contains(target) &&
    target.nodeName === "A" &&
    event.target.className !== "navigation__main" &&
    isNavigationVisible.value
  ) {
    closeMenu();
  }
};

onMounted(() => {
  document.addEventListener("click", documentClick);
});

onUnmounted(() => {
  closeMenu();
  document.removeEventListener("click", documentClick);
});
</script>

<template>
  <div
    class="back-office-site"
    :class="{ 'back-office-site--reapit': isInReapitAppDesktopMode }"
  >
    <ReapitAppHeader v-if="isInReapitAppDesktopMode" />
    <header class="back-office-site-header" role="banner" v-else>
      <div class="back-office-site-header__content">
        <router-link
          :to="{ name: 'back-office.dashboard' }"
          class="back-office-site-logo"
          :class="{
            [`back-office-site-logo--${brandLogoScale}`]: !!brandLogoScale,
          }"
          ><img
            :src="brandLogo"
            :alt="brandName"
            class="back-office-site-logo__graphic"
        /></router-link>

        <div
          class="back-office-site-navigation"
          role="navigation"
          :class="{
            'back-office-site-navigation--active': isNavigationVisible,
          }"
        >
          <div class="back-office-site-navigation__header">
            <span
              ref="navigationTrigger"
              class="back-office-site-navigation__trigger"
              aria-label="Menu"
              @click="toggleNavigationVisibility"
            >
              <menu-icon
                class="back-office-site-navigation__signifier"
                :is-open="isNavigationVisible"
              />
            </span>
          </div>

          <div class="back-office-site-navigation__main">
            <ul class="back-office-site-navigation-menu">
              <li class="back-office-site-navigation-menu__item"></li>

              <li class="back-office-site-navigation-menu__item">
                <router-link
                  :to="{ name: 'back-office.dashboard' }"
                  class="back-office-site-navigation-menu__anchor"
                  active-class="back-office-site-navigation-menu__anchor--active"
                  ><PropertyIcon
                    class="back-office-site-navigation-menu__icon"
                  />Properties</router-link
                >
              </li>
              <li
                class="back-office-site-navigation-menu__item"
                v-if="allowNativePropertyAdd"
              >
                <router-link
                  :to="{ name: 'agent.transaction.create' }"
                  class="back-office-site-navigation-menu__anchor"
                  active-class="back-office-site-navigation-menu__anchor--active"
                  ><AddIcon class="back-office-site-navigation-menu__icon" />Add
                  property</router-link
                >
              </li>
              <li class="back-office-site-navigation-menu__item" v-else>
                <router-link
                  :to="{ name: 'alto.search' }"
                  class="back-office-site-navigation-menu__anchor"
                  active-class="back-office-site-navigation-menu__anchor--active"
                  ><AddIcon class="back-office-site-navigation-menu__icon" />Add
                  from Alto</router-link
                >
              </li>
              <li
                class="back-office-site-navigation-menu__item"
                v-if="isOrganisationOwner"
              >
                <router-link
                  :to="{ name: 'back-office.organisation.team.manage' }"
                  class="back-office-site-navigation-menu__anchor"
                  active-class="back-office-site-navigation-menu__anchor--active"
                  ><TeamIcon
                    class="back-office-site-navigation-menu__icon"
                  />Team</router-link
                >
              </li>
              <li
                class="back-office-site-navigation-menu__item"
                v-if="isOrganisationOwner"
              >
                <router-link
                  :to="{ name: 'back-office.organisation.offices.list' }"
                  class="back-office-site-navigation-menu__anchor"
                  active-class="back-office-site-navigation-menu__anchor--active"
                  ><OfficeIcon
                    class="back-office-site-navigation-menu__icon"
                  />Offices</router-link
                >
              </li>
              <li
                class="back-office-site-navigation-menu__item"
                v-if="isOrganisationOwner"
              >
                <router-link
                  :to="{ name: 'back-office.organisation.details' }"
                  class="back-office-site-navigation-menu__anchor"
                  active-class="back-office-site-navigation-menu__anchor--active"
                  ><SettingsIcon
                    class="back-office-site-navigation-menu__icon"
                  />Settings</router-link
                >
              </li>
              <li class="back-office-site-navigation-menu__item">
                <router-link
                  :to="{ name: 'back-office.user.account' }"
                  class="back-office-site-navigation-menu__anchor"
                  active-class="back-office-site-navigation-menu__anchor--active"
                  ><UserIcon class="back-office-site-navigation-menu__icon" />My
                  account</router-link
                >
              </li>
              <li class="back-office-site-navigation-menu__item">
                <router-link
                  :to="{ name: 'back-office.organisation.help' }"
                  class="back-office-site-navigation-menu__anchor"
                  active-class="back-office-site-navigation-menu__anchor--active"
                  ><HelpIcon
                    class="back-office-site-navigation-menu__icon"
                  />Help and support</router-link
                >
              </li>
              <li class="back-office-site-navigation-menu__item" v-if="isAdmin">
                <h3 class="back-office-site-navigation-menu__heading">Admin</h3>
                <ul class="back-office-site-navigation-menu">
                  <li class="back-office-site-navigation-menu__item">
                    <router-link
                      :to="{ name: 'back-office.admin.transactions.list' }"
                      class="back-office-site-navigation-menu__anchor"
                      active-class="back-office-site-navigation-menu__anchor--active"
                      ><PropertyIcon
                        class="back-office-site-navigation-menu__icon"
                      />Transactions</router-link
                    >
                  </li>
                  <li class="back-office-site-navigation-menu__item">
                    <router-link
                      :to="{ name: 'back-office.admin.users' }"
                      class="back-office-site-navigation-menu__anchor"
                      active-class="back-office-site-navigation-menu__anchor--active"
                      ><UserIcon
                        class="back-office-site-navigation-menu__icon"
                      />Users</router-link
                    >
                  </li>
                  <li class="back-office-site-navigation-menu__item">
                    <router-link
                      :to="{ name: 'back-office.admin.organisation.list' }"
                      class="back-office-site-navigation-menu__anchor"
                      active-class="back-office-site-navigation-menu__anchor--active"
                      ><AdminIcon
                        class="back-office-site-navigation-menu__icon"
                      />Organisations</router-link
                    >
                  </li>
                  <li class="back-office-site-navigation-menu__item">
                    <router-link
                      :to="{ name: 'back-office.admin.emails' }"
                      class="back-office-site-navigation-menu__anchor"
                      active-class="back-office-site-navigation-menu__anchor--active"
                      ><EmailIcon
                        class="back-office-site-navigation-menu__icon"
                      />Emails</router-link
                    >
                  </li>
                  <li class="back-office-site-navigation-menu__item">
                    <router-link
                      :to="{ name: 'back-office.admin.webhooks' }"
                      class="back-office-site-navigation-menu__anchor"
                      active-class="back-office-site-navigation-menu__anchor--active"
                      ><WebhookIcon
                        class="back-office-site-navigation-menu__icon"
                      />Webhooks</router-link
                    >
                  </li>
                  <li class="back-office-site-navigation-menu__item">
                    <router-link
                      :to="{ name: 'back-office.admin.tools' }"
                      class="back-office-site-navigation-menu__anchor"
                      active-class="back-office-site-navigation-menu__anchor--active"
                      ><ToolIcon
                        class="back-office-site-navigation-menu__icon"
                      />Tools</router-link
                    >
                  </li>
                  <li class="back-office-site-navigation-menu__item">
                    <router-link
                      :to="{ name: 'back-office.admin.testing' }"
                      class="back-office-site-navigation-menu__anchor"
                      active-class="back-office-site-navigation-menu__anchor--active"
                      ><TaskSuccessIcon
                        class="back-office-site-navigation-menu__icon"
                      />Test suite</router-link
                    >
                  </li>
                </ul>
              </li>
            </ul>
            <div class="back-office-site-account">
              <div class="back-office-site-account__header">
                <Avatar
                  v-if="userOrganisation?.organisationLogo"
                  class="back-office-site-account__avatar"
                  :imageUrl="userOrganisation.organisationLogo"
                  :name="userOrganisation?.organisationName || ''"
                />
                <span class="back-office-site-account__aside">
                  <span
                    class="back-office-site-account__organisation-name"
                    v-if="userOrganisation?.organisationName"
                    >{{ userOrganisation.organisationName }}</span
                  ><span
                    class="back-office-site-account__user-name"
                    v-if="fullName"
                    >{{ fullName }}</span
                  ></span
                >
              </div>

              <div class="back-office-site-account__main">
                <InputButton
                  @click.prevent="onLogout"
                  data-test="logout"
                  class="button--block back-office-site-account__button"
                  >Sign out<template #icon-prefix><LogoutIcon /></template
                ></InputButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>

    <main role="main" class="back-office-site-main">
      <div class="back-office-site-main__content">
        <Suspense>
          <router-view />
          <template #fallback> Loading... </template>
        </Suspense>
      </div>
    </main>
  </div>
</template>

<style lang="scss">
@import "../assets/styles/index.scss";
@import "../assets/styles/back-office";
@import "../assets/styles/variables/back-office";

$back-office-site-header-desktop-width: 240px;
$back-office-site-header-mobile-height: 64px;
$back-office-site-navigation-main-width: 320px;

.back-office-site {
  background: #fafcff;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.back-office-site-header {
  background: #101740;
  transform: translateZ(0);
  display: flex;
  height: $back-office-site-header-mobile-height;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  max-width: 100vw;
  z-index: 300;
}

.back-office-site-header__content {
  display: flex;
  position: relative;
  margin: 0 auto;
  width: 100%;
}

.back-office-site-logo {
  height: 32px;
  width: auto;
  display: block;
  left: 16px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
}

.back-office-site-logo__graphic {
  height: 32px;
  width: auto;
  display: block;
}

.back-office-site-navigation {
  display: flex;
  right: 0;
  position: fixed;
  top: 0;
  width: 100%;
  justify-content: flex-end;
  z-index: 1;
}

.back-office-site-navigation--active {
  z-index: 10;

  .back-office-site-navigation__main {
    right: 0;
  }
}

.back-office-site-navigation__header {
  align-items: center;
  display: flex;
  min-height: $back-office-site-header-mobile-height;
  position: relative;
}

.back-office-site-navigation__trigger {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  height: 100%;
  justify-content: center;
  min-width: $back-office-site-header-mobile-height;
  padding: 0 16px;
  position: absolute;
  right: 0;
  text-align: center;
  z-index: 10;

  .icon--menu {
    &:after,
    &:before,
    & span {
      background-color: #fff;
    }
  }
}

.back-office-site-navigation__main {
  transition: right 0.1s ease;
  background: #101740;
  height: 100vh;
  right: negative($back-office-site-navigation-main-width);
  padding-top: $back-office-site-header-mobile-height;
  position: absolute;
  top: 0;
  transition-duration: 0.1s;
  transition-timing-function: ease-in-out;
  width: 100%;
  max-width: $back-office-site-navigation-main-width;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.back-office-site-main {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
  padding: $back-office-site-header-mobile-height 0 0;
}

/**
 * -----------------------------------------------------------------------------
 * Menu
 * -----------------------------------------------------------------------------
*/

.back-office-site-navigation-menu {
  list-style: none;
  margin: 0 0 stack();
  padding: 0;
  flex: 1;
}

.back-office-site-navigation-menu__item {
  & + .back-office-site-navigation-menu__item {
    margin-top: stack();
  }
}

.back-office-site-navigation-menu__icon {
  margin-right: gutter();

  .icon__glyph {
    fill: #fff;
  }
}

.back-office-site-navigation-menu__anchor {
  @include transition(background);
  color: #fff;
  text-decoration: none;
  display: flex;
  position: relative;
  font-weight: 600;
  align-items: center;
  padding: 11px 12px;
  font-size: 18px;
  line-height: 26px;

  background: transparent;
  border-radius: radius(weak);
  margin: 0 gutter();

  &:hover {
    background: rgba(#fff, 0.16);
  }
}

.back-office-site-navigation-menu__anchor--active {
  background: rgba(#fff, 0.16);

  .back-office-site-navigation-menu__icon {
    .icon__glyph {
      fill: $color-primary;
    }
  }
}

.back-office-site-navigation-menu__heading {
  text-transform: uppercase;
  font-size: 14px;
  line-height: 22px;
  margin: 32px 0 0 24px;
  color: #fff;
}

.back-office-site-account {
  background: rgba(#fff, 0.16);
  border-radius: radius(weak);
  margin: 0 gutter() stack() gutter();
  padding: stack() gutter();
}

.back-office-site-account__header {
  align-items: center;
  display: flex;
  margin: 0 0 stack(strong);
}

.back-office-site-account__avatar {
  margin-right: gutter();
}

.back-office-site-account__organisation-name {
  color: #fff;
  display: block;
  font-weight: 600;
  margin-bottom: stack(weak);
  word-break: break-word;
}

.back-office-site-account__user-name {
  color: #fff;
  display: block;
  font-size: typography-size("meta");
  font-weight: 400;
  line-height: typography-size("meta");
}

.back-office-site-account .back-office-site-account__button {
  .button__content {
    justify-content: flex-start;
  }
}

@include mq("large-viewport") {
  .back-office-site {
    flex-direction: row;
  }

  .back-office-site-header {
    height: 100%;
    position: fixed;
    width: $back-office-site-header-desktop-width;
  }

  .back-office-site-header__content {
    flex-direction: column;
    flex: 1;
    max-height: 100vh;
  }

  .back-office-site-logo {
    width: auto;
    position: static;
    transform: none;
    margin: 32px 16px 64px;
  }

  .back-office-site-logo__graphic {
    height: 40px;
  }

  .back-office-site-navigation {
    flex: 1;
  }

  .back-office-site-navigation__header {
    display: none;
  }

  .back-office-site-navigation {
    position: static;
  }

  .back-office-site-navigation__main {
    position: static;
    padding-top: 0;
    height: auto;
  }

  .back-office-site-main {
    display: flex;
    flex: 1;
    margin-left: $back-office-site-header-desktop-width;
    margin-bottom: 90px;
    padding: 0;
  }
}

// .back-office-site {
//   background: #fafcff;
//   display: flex;
//   flex-direction: row;
//   min-height: 100vh;
// }

// .back-office-site-header {
//   background: #101740;
//   display: flex;
//   height: 100%;
//   position: fixed;
//   transform: translate3d(0, 0, 0);
//   width: 100%;
//   width: $back-office-site-header-width;
//   z-index: 300;
// }

// .back-office-site-header__content {
//   display: flex;
//   flex-direction: column;
//   flex: 1;
//   max-height: 100vh;
// }

// .back-office-site-logo {
//   display: flex;
//   justify-content: center;
//   margin: stack(strongest) 0 stack(strongest) * 2;
//   z-index: 10;
// }

// .back-office-site-logo--strong {
//   .back-office-site-logo__graphic {
//     height: 56px;
//   }
// }

// .back-office-site-logo__graphic {
//   height: 40px;
//   width: auto;
// }

// .back-office-site-main {
//   display: flex;
//   flex: 1;
//   margin-left: $back-office-site-header-width;
//   margin-bottom: $safe-area-inset-bottom;
// }

// .back-office-site-main__content {
//   display: flex;
//   flex: 1;
//   flex-direction: column;
// }

// .back-office-content {
//   display: flex;
//   flex-direction: column;
//   flex: 1;
// }

.back-office-content__title {
  margin: 0;
}

.back-office-content__subtitle {
  margin: stack() 0 0;
}

.back-office__affiliate-logo {
  height: 24px;
  width: auto;
}

.back-office-content__main {
  background: #fff;
  display: flex;
  flex-direction: column;
  flex: 1;
  border-top: solid 2px #e9e9e9;
  padding: stack(strongest) gutter(strongest);
}

.back-office-content--modal {
  .back-office-content__main {
    padding: 0;
    margin: 0;
    background: transparent;
    flex: 1;
    border-top: none;
  }
}

.back-office-content--tabs {
  margin-bottom: negative($safe-area-inset-bottom);

  .back-office-content__main {
    padding: 0;
    border: 0;
    background: transparent;
    flex: 1 1 100%;
  }
  .back-office-content__header {
    padding-bottom: stack(strong);
  }

  .back-office-tabs {
    .tabs-section {
      padding-bottom: $safe-area-inset-bottom;
    }
  }
}

.back-office-tabs {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;

  .tabs-nav {
    padding: 0 gutter(strong);

    @include mq("large-viewport") {
      padding: 0 gutter(strongest);
    }
  }

  .tabs-main {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  .tabs-section {
    background: #fff;
    flex: 1;
    padding: stack(strongest) gutter(strongest);
    min-height: 100vh;
  }

  .tabs-nav__trigger {
    &:hover {
      color: $back-office-color-secondary;

      .icon__glyph {
        fill: $back-office-color-secondary;
      }

      &:after {
        background: $back-office-color-secondary;
      }
    }
  }
  .tabs-nav__trigger--active {
    color: $back-office-color-secondary;

    &:after {
      background: $back-office-color-secondary;
    }
  }
}

.back-office-content-menu {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: negative(stack(strongest)) 0 0 negative(gutter(strongest));
}

.back-office-content-menu__main {
  margin: stack(strongest) 0 0 gutter(strongest);
}

.back-office-content-menu__aside {
  order: 2;
  margin: stack(strongest) 0 0 gutter(strongest);
  min-width: 216px;
  max-width: 216px;
}

.back-office-content-menu__main {
  flex: 1;
}

.back-office-content-menu-nav {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  flex: 1;

  & + .back-office-content-menu-nav {
    margin-top: stack(strongest);
  }
}

.back-office-content-menu-nav__item {
  & + .back-office-content-menu-nav__item {
    margin-top: stack();
  }

  & + .back-office-content-menu-nav__item--button {
    margin-top: stack(strongest);
  }
}

.back-office-content-menu-nav__item--button {
  & + .back-office-content-menu-nav__item--button {
    margin-top: stack(strong);
  }
}

.back-office-content-menu-nav__item--break {
  margin-bottom: stack(strongest);
}

.back-office-site-navigation-menu__heading {
  text-transform: uppercase;
  font-size: 14px;
  line-height: 22px;
  margin: 32px 0 0 24px;
  color: #fff;
}

/**
 *----------------------------------------
  SECTION
 *----------------------------------------
 */

.back-office-section {
  & + .back-office-section {
    margin-top: stack(strongest) + stack(strongest);
  }
}

.back-office-section--panel {
  background: $color-panel-100;
  border-radius: radius(weak);
  max-width: $max-main-width;
  padding: stack(strong) gutter(strong);
}

.back-office-section--sub {
  padding: 32px;
  background: #fafcff;
  margin-left: -32px;
  margin-right: -32px;
  border-top: solid 2px #e9e9e9;
}

.back-office-section__main {
  background: #fff;
  border: solid 2px #e9e9e9;
  padding: 32px;
}

.back-office-section--group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: -64px 0 0 -64px;
}

.back-office-section--aside {
  flex-direction: column;
  display: flex;
  flex: 1;
  margin: 64px 0 0 64px;
  min-width: $max-aside-width;

  .back-office-section__main {
    background: #fff;
    border: none;
    padding: 0;
  }

  .back-office-section__aside {
    margin-left: auto;
  }
}

.back-office-data-definition-section {
  & + .back-office-data-definition-section {
    margin-top: stack(strongest);
  }
}

.back-office-data-definition {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: $max-main-width;
  border-top: solid 2px $color-stroke;
  border-bottom: solid 2px $color-stroke;
  padding: stack(default-strong) 0;

  & + .back-office-data-definition {
    border-top: 0;
  }

  &:last-of-type {
    margin-bottom: stack(strong);
  }
}

.back-office-data-definition__main {
  grid-column: 1/-1;

  .back-office-data-definition {
    &:first-of-type {
      padding-top: 0;
      border-top: 0;
    }

    &:last-of-type {
      padding-bottom: 0;
      border-bottom: 0;
      margin-bottom: 0;
    }
  }
}

.back-office-data-definition__header {
  & + .back-office-data-definition__main {
    grid-column: auto;
  }
}

.back-office-data-definition__header {
  padding: 0 gutter(strong) 0 0;
}

.back-office-data-definition__small {
  color: $color-typography-meta;
  font-size: typography-size("meta");
  line-height: typography-line-height("meta");
  margin: 0;
}

.back-office-data-definition__description {
  font-weight: 600;
  margin: 0;
}
.back-office-data-definition__heading {
  font-size: typography-size("body");
  line-height: typography-line-height("body");
  margin: 0;
  font-weight: 500;

  & + .back-office-data-definition__small {
    margin-top: stack();
  }
}

/**
 * Restrict sticky menu to desktop, just to be safe
 */
@include mq("medium-viewport") {
  .back-office-content-menu__aside__content--sticky {
    position: sticky;
    top: stack(strong);
  }

  .back-office-content-menu__aside--sticky {
    position: sticky;
    top: stack(strong);
  }
}
</style>
