import iso3ToIso2 from "@/enums/iso3-iso2";
import { parse } from "postcode";

/**
 * Undocumented, at least one phone number is required:
 * 'Mobile Phone Number' must not be empty.
 * 'Work Phone Number' must not be empty.
 * 'Home Phone Number' must not be empty.
 */
const formatClientRequest = (participant) => {
  const clientDetails = {
    title: participant?.name?.title || "",
    forenames: `${participant?.name?.firstName || ""} ${
      participant?.name?.middleName || ""
    }`.trim(),
    surname: participant?.name?.lastName || "",
  };

  // Don't provide an empty string, though it seems LMS requires at least one number (undocumented).
  if (participant?.phone) {
    clientDetails.mobileNumber = participant?.phone || "";
  }

  clientDetails.email = participant?.email || "";

  clientDetails.address = formatQuoteAddress(participant?.address || {});

  return clientDetails;
};

const formatQuoteAddress = (address) => {
  const parsedPostcode = parse(address?.postcode?.trim() || "");

  let country = "";

  if (address.countryIso2) {
    country = address.countryIso2;
  }

  if (address.countryCode) {
    country = iso3ToIso2[address.countryCode];
  }

  return {
    houseNumber: address?.buildingNumber?.trim() || "Not provided",
    streetName:
      address?.street?.trim() || address?.line1?.trim() || "Not provided",
    districtName: address?.provinceName?.trim() || "",
    postTown: address?.city?.trim() || address?.line2?.trim() || "Not provided",
    county: address?.province?.trim() || "",
    postcode1: parsedPostcode.outcode || "",
    postcode2: parsedPostcode.incode || "",
    country,
  };
};

export default function useConveyancingQuoteLms() {
  return {
    formatQuoteAddress,
    formatClientRequest,
  };
}
