export const checkSeverity = Object.freeze({
  WARNING: "warning",
  SUCCESS: "success",
  ERROR: "error",
  INFO: "info",
});

export const checkType = Object.freeze({
  FLAG: "flag",
});

export const checkSource = Object.freeze({
  COLLECTOR: "collector",
  USER_ACTION: "userAction",
  DILIGENCE: "diligence",
});

export const contentKeys = Object.freeze({
  DEFAULT_MESSAGE: "message",
  DEFAULT_HEADING: "heading",
  SELLER: "seller",
  BUYER: "seller",
});

export const collectorState = Object.freeze({
  AVAILABLE: "available", // Can be run and hasn't started
  RUNNING: "running", // Is underway
  SETTLED: "settled", // Has resolved into a success/pending-retrying/fail state
});
