import useConsumerCharges from "@/composables/consumerCharges";
import useAuth from "@/composables/auth";

const redirectToPaymentGateway = async (to, from, next) => {
  const { fetchConsumerCharges, hasConsumerCharges } = useConsumerCharges();
  const { userId } = useAuth();

  try {
    await fetchConsumerCharges(to.params.transactionId, userId.value);
  } catch (ex) {
    return next({ name: "500" });
  }

  // If charges are paid and we're going to checkout, redirect to property view
  if (
    !hasConsumerCharges.value &&
    ["consumer-charges.checkout.payment", "consumer-charges.checkout"].includes(
      to.name
    )
  ) {
    return next({
      name: "property.view",
      params: { transactionId: to.params.transactionId },
    });
  }

  // Don't run further checks if we're going to checkout
  if (
    ["consumer-charges.checkout.payment", "consumer-charges.checkout"].includes(
      to.name
    )
  ) {
    next();
    return;
  }
  if (hasConsumerCharges.value) {
    return next({
      name: "consumer-charges.checkout",
      params: { transactionId: to.params.transactionId },
    });
  }

  return next();
};

export default redirectToPaymentGateway;
