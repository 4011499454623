import setDefaultBranding from "../guards/setDefaultBranding";

export default [
  {
    path: "/",
    name: "home",
    beforeEnter: [setDefaultBranding],
    component: () => import("../../views/content/Home.vue"),
    meta: {
      bodyClass: "body--landing",
      isAccountPage: false,
      metaTags: [
        {
          name: "description",
          content:
            "Moverly provides Material Information compliance in moments for estate agents.",
        },
      ],
    },
  },
  {
    path: "/agents",
    name: "agents.landing",
    component: () => import("../../views/content/AgentsLanding.vue"),
    meta: {
      bodyClass: "body--landing",
      isAccountPage: false,
      metaTags: [
        {
          name: "description",
          content:
            "Moverly helps you get properties listed and vendors onboarded in record time. Sales complete more often, and more quickly.",
        },
      ],
    },
  },
  {
    path: "/sellers",
    name: "sellers.landing",
    component: () => import("../../views/content/SellersLanding.vue"),
    meta: {
      title: "Sellers",
      bodyClass: "body--landing",
      isAccountPage: false,
      metaTags: [
        {
          name: "description",
          content:
            "Moverly helps you get listed fast, and maximises the likelihood your sale will be speedy and successful.",
        },
      ],
    },
  },
  {
    path: "/conveyancers",
    name: "conveyancers.landing",
    component: () => import("../../views/content/ConveyancersLanding.vue"),
    meta: {
      title: "Conveyancers",
      bodyClass: "body--landing",
      isAccountPage: false,
      metaTags: [
        {
          name: "description",
          content:
            "Moverly captures the full range of identity and property data from provably trusted sources, freeing you up to do what you do best.",
        },
      ],
    },
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../../views/content/AboutUs.vue"),
    meta: {
      title: "About Moverly",
      bodyClass: "body--landing",
      isAccountPage: false,
      metaTags: [
        {
          name: "description",
          content:
            "Moving home is stressful enough, but today's archaic processes and disconnected systems often turn it into a confused, frustrating nightmare.",
        },
      ],
    },
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("../../views/content/ContactUs.vue"),
    meta: {
      title: "Get in touch",
      isAccountPage: false,
      bodyClass: "body--landing",
    },
  },
  {
    path: "/enquiry",
    name: "enquiry",
    component: () => import("../../views/content/EnquiryForm.vue"),
    meta: {
      title: "Get in touch",
      isAccountPage: false,
      bodyClass: "body--landing",
    },
  },
  {
    path: "/help",
    name: "help",
    component: () => import("../../views/content/HelpPage.vue"),
    meta: {
      title: "Help and support",
      isAccountPage: false,
      bodyClass: "body--landing",
    },
  },
  {
    path: "/pricing",
    name: "pricing",
    component: () => import("../../views/content/Pricing.vue"),
    meta: {
      title: "Pricing",
      isAccountPage: false,
      bodyClass: "body--landing",
    },
  },
];
