import redirectToPaymentGateway from "../guards/redirectToPaymentGateway";

export default [
  {
    path: "checkout",
    name: "consumer-charges.checkout",
    beforeEnter: [redirectToPaymentGateway],
    component: () =>
      import(
        /* webpackChunkName: "consumer-charges" */ "../../views/consumer-charges/Introduction.vue"
      ),
    props: (route) => ({
      transactionId: route.params.transactionId,
    }),
    meta: {
      title: "Checkout",
    },
  },
  {
    path: "checkout/payment",
    name: "consumer-charges.checkout.payment",
    beforeEnter: [redirectToPaymentGateway],
    component: () =>
      import(
        /* webpackChunkName: "consumer-charges" */ "../../views/consumer-charges/Payment.vue"
      ),
    props: (route) => ({
      transactionId: route.params.transactionId,
    }),
    meta: {
      title: "Payment",
    },
  },
  {
    path: "checkout/confirmation",
    name: "consumer-charges.checkout.confirmation",
    component: () =>
      import(
        /* webpackChunkName: "consumer-charges" */ "../../views/consumer-charges/Confirmation.vue"
      ),
    props: (route) => ({
      transactionId: route.params.transactionId,
    }),
    meta: {
      title: "Confirmation",
    },
  },
];
